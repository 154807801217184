<template>
  <div>
    <div class="row mb-4">
      <h1 class="col-sm-12 text-right">Catálogos</h1>
    </div>

    <DataGrid :configuracion="datagrid_config" :data="catalogos" @ready="buscar_botones">
      <button class="btn btn-warning mr-2" @click="obtener_catalogos">Recargar</button>
      <button class="btn btn-primary" @click="modal_nuevo=true">Agregar catálogo</button>
    </DataGrid>

    <Nuevo v-if="modal_nuevo" :catalogo="catalogo" @update="actualizar" @close="cancelar_catalogo"/>
    <Eliminar v-if="modal_eliminar" :catalogo="catalogo" @update="actualizar" @close="cancelar_catalogo"/>
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV3'
  import Nuevo from './Catalogo/Nuevo'
  import Eliminar from './Catalogo/Eliminar'

  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    components: {
      DataGrid, Nuevo, Eliminar
    }
    ,data() {
      return {
        datagrid_config: {
          name: 'catalogos'
          ,selector: false
          ,cols: {
            id: 'ID'
            ,nombre: 'Nombre'
            ,codigo: 'Código'
            ,estatus: 'Estatus'
            ,created_at: 'Opciones'
          }
          ,mutators: {
            estatus(val) {
              return val == 1 ? 'Activo' : 'Inactivo';
            }
            ,created_at(val,row, vue) {
              return '<img class="mr-2 btn-catalogo" data-action="editar" data-catalogo="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar catálogo" title="Editar catálogo" />'
                  +  '<img class="btn-catalogo" data-action="eliminar" data-catalogo="'+vue.$helper.toJson(row)+'" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar catálogo" title="Elimitar catálogo" />';
            }
          }
        }
        ,catalogos: []
        ,modal_nuevo: false
        ,modal_eliminar: false
        ,catalogo: {
          nombre: null
          ,codigo: null
          ,estatus: 1
          ,opciones: []
        }
      }
    }
    ,mounted() {
      this.obtener_catalogos();
    }
    ,methods: {
      async obtener_catalogos() {
        try {
          this.catalogos = (await apiCatalogo.obtener_catalogos()).data
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_botones() {
        let btns = document.querySelectorAll('.btn-catalogo');
        
        btns.forEach(btn => {
          btn.removeEventListener('click', (e) => {
            this.ejecutar_accion(e);
          }, false);

          btn.addEventListener('click', (e) => {
            this.ejecutar_accion(e);
          }, false);
        })
      }
      ,ejecutar_accion(e) {
        let catalogo = this.$helper.fromJson(e.target.dataset.catalogo);
        let accion = e.target.dataset.action;
        
        this.catalogo = catalogo;
        if (accion == 'editar')
          this.modal_nuevo = true;
        else
          this.modal_eliminar = true;
      }
      ,cancelar_catalogo() {
        this.modal_nuevo = false;
        this.modal_eliminar = false;
        this.catalogo = {
          nombre: null
          ,codigo: null
          ,estatus: 1
          ,opciones: []
        }
      }
      ,actualizar() {
        this.obtener_catalogos();
        this.cancelar_catalogo();
      }
    }
  }
</script>

<style lang="scss">
  table {
    tbody {
      tr {
        td {
          img {
            width: 20px;
          }
        }
      }
    }
  }
</style>